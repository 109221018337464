<template>
  <div>
    <img :src="this.bannerCont.pic">
    <div id="banner-content">
      <div id="abouts">
        <h4>{{ this.bannerCont.title }}</h4>
        <p>{{ this.bannerCont.txt }}</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Banner",
  components: {

  },
  props: {
    bannerCont: Object,
  },
  data() {
    return {

    }
  },
  methods: {

  }
}
</script>

<style scoped>
div {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
}
div > img {
  width: 100%;
}
div > div#banner-content {
  position: relative;
  top: -260px;
  max-width: 1280px;  
  width: 100%;
  margin: 0 auto;
  display: block;
  z-index: 100;
}
div > div#banner-content > div#abouts {
  position: absolute;
  width: 42%;
  float: left;
}
div > div#banner-content > div#abouts > h4 {
  display: block;
  width: 100%;
  padding-bottom: 6px;
  font-size: 1.625rem;
  color: #ffffff;
  font-weight: bold;
  line-height: 2.5rem;
  text-align: left;
}
div > div#banner-content > div#abouts > p {
  font-size: 15px;
  padding-bottom: 0px;
  color: #FFFFFF;
  line-height: 1.5rem;
  opacity: 0.8;
}

</style>