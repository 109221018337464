<template>  
  <div calss="swiper-container" v-swiper:mySwiper="swiperOptions">
    <div class="swiper-wrapper">
      <div class="swiper-slide" :key="index" v-for="item,index in dataList">
        <h5>{{ item.date }}</h5>
        <p>{{ item.txt }}</p>
      </div>
    </div>
    <div class="swiper-pagination"></div>
  </div>
</template>

<script>
export default {
  name: "Locus",
  props: {
    dataList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      swiperOptions: {
        loop: true,   // 循环播放
        breakpoints: { 
          320: {  //当屏幕宽度大于等于320
            slidesPerView: 2,
            spaceBetween: 100
          },
          768: {  //当屏幕宽度大于等于768 
            slidesPerView: 3,
            spaceBetween: 100
          },
          1280: {  //当屏幕宽度大于等于1280
            slidesPerView: 4,
            spaceBetween: 100
          }
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          clickableClass : 'my-pagination-clickable'
        },
      },
    }
  },
  mounted() {
    this.moveAction();
  },
  methods: {
    // 自动播放
    moveAction() {
      setInterval(() => {
        this.mySwiper.slideNext(500, false);
      }, this.dataList.length * 600);
    },
  }
}
</script>

<style scoped>
div.swiper-container > div.swiper-wrapper > div.swiper-slide > h5 {
  font-size: 1.375rem;
  color: #ffffff;
  text-align: center;
  font-weight: normal;
  padding-bottom: 3.125rem;
  background: url('../../../../../assets/about02-03.png') no-repeat bottom center;
  margin-bottom: 3.125rem;
}
div.swiper-container {
  height: 380px;
}
div.swiper-container > div.swiper-wrapper > div.swiper-slide > p {
  display: block;
  font-size: 1rem;
  color: #fff;
  line-height: 1.875rem;
  text-align: justify;
  text-justify: distribute;
}
/* 指示器定位 */
div.swiper-container > div.swiper-pagination {
  position: relative;
  z-index: 1000;
  top: -18px;
}
/* 指示器颜色 */
/* .swiper-container{
  --swiper-theme-color: #fff;
} */
</style>