<template>
  <div id="abouts-content">
      <Banner :bannerCont="bannerCont"/>
      <div id="bread-content">
        <div class="bread-item">
          <router-link
            v-for="item,index in this.bannerCont.subMenu" :key="index"
            :to="`${ item.url }`"
          >
            {{ item.title }}
          </router-link>
        </div>
      </div>
    <!-- 一楼简介 -->
      <div id="abouts-index">
        <div id="title">
          公司<span>简介</span>
        </div>
        <div id="txt">
          <p>西安核创源科技有限公司创立于二〇一八年一月，公司以服务国家重大科技需求为使命，以促进产学研结合和科技成果转化为宗旨，以核能及相关领域的应用软件自主化研发和技术服务为核心业务，为国内外核能及相关领域提供软件研发、技术攻关、技术服务与咨询等。</p>
          <p>公司已自主研发了压水堆燃料管理计算软件Bamboo系列、先进快谱反应堆分析系统软件SARAX系列、概率统计方法输运软件MCX、下一代高保真一步法计算软件X系列、高性能大规模输运计算软件Hydra系列和多用途核数据库制作系统软件Atlas系列等。同时，公司针对核能领域的不同需求，提供各类技术服务和咨询服务，包括核电厂换料校核计算，调试技术支持，运行技术改造，分析软件研发，数据处理等。</p>
          <router-link to="/profile/abouts">
            <span>了解详情</span>
          </router-link>
        </div>
        <div id="pic">
          <div id="img">
            <img :src="require('../../../assets/about01-01.png')">
          </div>
          <div id="pic-txt">
            <p>公司坚持以需求为导向，面向复杂的实际工程问题提供解决方案，坚持以量身定制技术为核心，做到精心服务全方位掌控；坚持以自主攻关为使命，为攻克卡脖子难题做出自己的贡献。</p>
            <span></span>
          </div>
        </div>
      </div>
      <!-- 二楼历程 -->
      <div id="progress">
        <div id="pro-content">
          <div id="title">
            <router-link to="/profile/progress">发 展 历 程</router-link>
          </div>
          <Locus :dataList="progessList"/>
        </div>
        <div id="spect"></div>
      </div>
      <!-- 三楼使命 -->
      <div id="duty">
        <div id="duty-content">
          <div id="title">
            <router-link to="/profile/duty">使 命 与 责 任</router-link>
          </div>
          <div id="duty-card">
            <div class="card-content"
              v-for="item,index in dutyList" :key="index"
            >
              <router-link to='/Profile'>
                <div class="card-header">
                  <h5>{{ item.title }}</h5>
                  <div class="card-pic">
                    <img :src="require('../../../assets/about03-' + `${ item.pic }` + '.png')">
                  </div>
                </div>
                <div class="card-footer">
                  <p>{{ item.txt }}</p>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <!-- 四楼资质认证 -->
      <div id="immp">
        <div id="immp-content">
          <div id="title">
            <router-link to="/profile/immp">资 质 认 证</router-link>
          </div>
          <ImmpPic :dataList="immpPicList"/>
          <router-link to="/profile/immp"><i>查看更多</i></router-link>
        </div>
      </div>
      <!-- 五楼联系我们 -->
      <div id="contant">
        <div id="contant-content">
          <div id="title">
            <router-link to="/profile/contant">联 系 我 们</router-link>
          </div>
          <div id="contant-card">
            <div class="card-content"
              v-for="item,index in contantList" :key="index"
            >
              <router-link :to="{ path: '/Profile' }">              
                <div class="card-pic">
                  <img :src="require('../../../assets/about05-' + `${ item.pic }` + '.png')">
                </div>                
                <p>{{ item.txt }}</p>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div id="page-footer">
        <Footer></Footer>
      </div>
  </div>
</template>

<script>
import Banner from '../common/Banner.vue'
import Locus from '../../pc/common/content/profile/Locus'
import ImmpPic from '../../pc/common/content/profile/ImmpPic'
import Footer from '../../pc/common/Footer'
export default {
  name: "Index",
  components : {
    'Locus': Locus,
    'ImmpPic': ImmpPic, 
    'Footer': Footer,
    'Banner': Banner
  },
  data() {
    return {
      bannerCont: {
        pic: require('../../../assets/index.png'),
        title: "公司简介",
        txt: "面向复杂的实际工程问题提供解决方案, 做到精心服务全方位掌控, 为攻克卡脖子难题做出自己的贡献坚持以需求为导向，以量身定制技术为核心，以自主攻关为使命",
        subMenu: [
          { title: "公司简介", url: "/profile/abouts" },
          { title: "发展历程", url: "/profile/progress" },
          { title: "使命与责任", url: "/profile/duty" },
          { title: "资质认证", url: "/profile/immp" },
          { title: "联系我们", url: "/profile/contact" }
        ]
      },
       // 历程数据
      progessList: [
        { date: '2018.1.17', txt: "西安核创能源科技有限公司在西安高新技术开发区注册成立" },
        { date: '2018.5.28', txt: "公司自主研发的“压水堆堆芯核设计系统[简称:Bamboo] V1.0” 在国家版权局完成软件著作权登记（登记号：2018SR389280）" },
        { date: '2018.12.1', txt: "公司与中山大学签署软件开发合同" },
        { date: '2018.12.5', txt: "公司通过质量管理体系（ISO 9001:2005）、环境管理体系（ISO 14001:2005）和职业健康安全体系（ISO 18001:2007）认证" },
        { date: '2019.06.10', txt: "公司成为中国核工业集团公司的合格供应商" },
        { date: '2019.8.1', txt: "公司自主研发的“蒙特卡罗粒子输运计算软件[简称: MCX] V1.0” 在国家版权局完成软件著作权登记（登记号：2019SR0799806）" },
        { date: '2019.3.1', txt: "公司与国家电投集团科学技术研究院有限公司签署软件开发合同" }
      ],
      // 使命责任数据
      dutyList: [
        { title: '公司愿景', txt: '架起核能行业内科研与产业之间的桥梁', pic: '01' },
        { title: '公司使命', txt: '将最新科研成果转化为先进核能的生产力，助推我国核能技术创新', pic: '02' },
        { title: '公司理念', txt: '以核为贵、创新发展', pic: '03' },
        { title: '公司口号', txt: '核能创造美好未来', pic: '04' }
      ],
      // 资质认证数据
      immpPicList: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
      // 联系我们数据
      contantList: [
        { pic: '01', txt: 'Official@necphc.com' },
        { pic: '02', txt: 'http://www.necphc.com' },
        { pic: '03', txt: '1709134171' },
        { pic: '04', txt: '029-81148661' }
      ],
    }
  }
}
</script>

<style>
/* 导航 */
div#bread-content {
  position: relative;
  top: -3px;
  max-width: 1920px;
  margin: 0 auto;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 9.9px 0.1px rgba(136, 99, 255, 0.29);
}
div#bread-content div.bread-item {
  position: relative;
  max-width: 1280px;
  line-height: 3.75rem;
  margin: 0 auto;
}
div#bread-content div.bread-item > a {
  display: inline-block;
  line-height: 3.75rem;
  font-size: 1rem;
  margin: 0 1.875rem;
  text-decoration: none;
  outline: none;
  color: #323232;
}
div#bread-content div.bread-item > a:hover {
  color: #0066e4;
  transition: 0.6s all;
}
/* 一楼简介 */
div#abouts-index {
  max-width: 1280px;
  margin: 0 auto;
  margin-top: 0px;
  margin-top: 5rem;
}
div#abouts-index > div#title {
  font-size: 26px;
  color: #323232;
  height: 40px;
  line-height: 40px;
  padding-bottom: 10px;
  border-bottom: 1px solid #2784ff;
  display: table;
  margin-bottom: 3.125rem;
}
div#abouts-index > div#title > span {
  font-size: 26px;
  color: #2784ff;
}
div#abouts-index > div#txt {
  float: left;
  width: 55%;
  padding-top: 4.375rem;
}
div#abouts-index > div#txt > p {
  font-size: 1rem;
  line-height: 2rem;
  color: #666666;
  text-indent: 2rem;
  display: block;
  text-align: justify;
	text-justify: distribute;
}
div#abouts-index > div#txt > a {
  display: block;
  margin-top: 5.625rem;
  width: 24%;
  height: 44px;
  line-height: 44px;
  border-radius: 22px;
  background: url('../../../assets/about01-04.png') repeat-x center;
  text-decoration: none;    
  outline: none;
}
div#abouts-index > div#txt > a > span {
  transition: 0.6s all;
  padding-right: 25%;
  background: url('../../../assets/about01-03.png') no-repeat right center;
  background-size: auto;
  background-size: 30%;
  display: table;
  margin: 0 auto;
  font-size: 1rem;
  color: #fff;
}
div#abouts-index > div#txt > a:hover > span {
  padding-right: 30%;
  transition: 0.6s all;
}
div#abouts-index > div#pic {
  width: 40%;
  overflow: hidden;
  float: right;
  height: auto;
  position: relative;
  padding-bottom: 2.5rem;
}
div#abouts-index > div#pic > div#img {
  width: 80%;
  overflow: hidden;
}
div#abouts-index > div#pic > div#img > img {
  display: block;
  width: 100%;
  transition: 0.6s all;
}
div#abouts-index > div#pic:hover > div#img > img {
  transition: 0.6s all;
  transform: scale(1.1);
}
div#abouts-index > div#pic > div#pic-txt {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50%;
  padding: 1.875rem;
  background-color: rgba(0,102,228,0.85);
  border: 4px solid rgba(255,255,255,0.85);
}
div#abouts-index > div#pic > div#pic-txt > p {
  padding-top: 4.625rem;
  font-size: 1rem;
  line-height: 2rem;
  color: #fff;
  background: url('../../../assets/about01-02.png') no-repeat left top;
  background-size: 16%;
}
div#abouts-index > div#pic > div#pic-txt > span {
  transition: 0.6s all;
  display: block;
  float: right;
  margin-top: 1.25rem;
  width: 3.75rem;
  height: 3.75rem;
  background: url('../../../assets/about01-05.png') no-repeat center;
  background-size: 100%;
}
div#abouts-index > div#pic:hover > div#pic-txt > span {
  margin-top: 3.75rem;
  transition: 0.6s all;
}
/* 二楼历程 */
div#progress {
  position: absolute;
  top: 1320px;
  background: url('../../../assets/about02-01.png') no-repeat center;
  margin-top: 3.125rem;
  padding: 3.125rem 0 4.375rem 0;
  margin: 0 auto;
  max-width: 1920px;
  width: 100%;
}
div#progress > div#spect  {
  height: 1.375rem;
  width: 100%;
  position: absolute;
  top: 200px;
  background: url('../../../assets/about02-02.png') no-repeat center;
}
div#progress > div#pro-content  {
  position: relative;
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
}
div#progress > div#pro-content > div#title > a {
  transition: 0.6s all;
  font-size: 26px;
  color: #fff;
  height: 40px;
  line-height: 40px;
  padding-bottom: 10px;
  border-bottom: 1px solid #fff;
  display: table;
  margin: 0 auto 3.125rem auto;
  text-decoration: none;
  outline: none;
}
div#progress > div#pro-content > div#title > a:hover {
  opacity: 0.5;
  transition: 0.6s all;
}
/* 三楼使命 */
div#duty {
  position: absolute;
  top: 2000px;
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
}
div#duty > div#duty-content {
  position: relative;
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
}
div#duty > div#duty-content div#title > a {
  margin: 0 auto;
  font-size: 26px;
  color: #0066e4;
  height: 40px;
  line-height: 40px;
  padding-bottom: 10px;
  border-bottom: 1px solid #2784ff;
  display: table;
  text-decoration: none;
  outline: none;  
  margin-bottom: 50px;
}
div#duty > div#duty-content > div#duty-card:after {
  clear: both;
  content: '';
  display: block;
  width: 0;
  height: 0;
  visibility: hidden;
}
div#duty > div#duty-content > div#duty-card > div.card-content {
  float: left;
  width: 22%;
  margin-right: 4%;
  box-shadow: 0px 0px 11.64px 0.36px rgba(136, 99, 255, 0.38);
  background: #fff;
  transition: 0.6s all;
}
div#duty > div#duty-content > div#duty-card > div.card-content:hover {
  transform: scale(1.1);
  transition: 0.6s all;
}
div#duty > div#duty-content > div#duty-card > div.card-content:last-child {
  margin: 0;
}
div#duty > div#duty-content > div#duty-card > div.card-content > a {
  display: block;
  text-decoration: none;
  color: #000;
  outline: none;
}
div#duty > div#duty-content > div#duty-card > div.card-content > a > div.card-header {
  background: #f7f7f7;
  height: 6.25rem;
  position: relative;
}
div#duty > div#duty-content > div#duty-card > div.card-content > a > div.card-header > h5 {
  padding-top: 1.25rem;
  font-size: 1.125rem;
  color: #333333;
  text-align: center;
  font-weight: normal;
}
div#duty > div#duty-content > div#duty-card > div.card-content > a > div.card-header > div.card-pic {
  position: absolute;
  bottom: -2.5rem;
  width: 24%;
  left: 50%;
  margin-left: -12%;
}
div#duty > div#duty-content > div#duty-card > div.card-content > a > div.card-header > div.card-pic > img {
  width: 100%;
}
div#duty > div#duty-content > div#duty-card > div.card-content > a > div.card-footer {
  padding: 5rem 1.5rem 2.5rem 1.5rem;
}
div#duty > div#duty-content > div#duty-card > div.card-content > a > div.card-footer > p {
  font-size: 1rem;
  line-height: 1.875rem;
  color: #666666;
  text-align: center;
  height: 3.75rem;
  overflow: hidden;
}
/* 四楼资质认证 */
div#immp {
  position: absolute;
  top: 2450px;
  background: url('../../../assets/about04-01.png') no-repeat center;
  margin-top: 3.125rem;
  padding: 3.125rem 0 4.375rem 0;
  margin: 0 auto;
  max-width: 1920px;
  width: 100%;
}
div#immp > div#immp-content  {
  position: relative;
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
}
div#immp > div#immp-content > div#title > a {
  font-size: 26px;
  color: #fff;
  height: 40px;
  line-height: 40px;
  padding-bottom: 10px;
  border-bottom: 1px solid #fff;
  display: table;
  margin: 0 auto 3.125rem auto;
  text-decoration: none;
  outline: none;
}
div#immp > div#immp-content > a {
  display: block;
  width: 140px;
  height: 34px;
  line-height: 34px;
  margin: 5rem auto 0 auto;
  border: 1px solid #cccccc;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  outline: none;
}
div#immp > div#immp-content > a > i {
  padding-right: 50px;
  background: url('../../../assets/about04-05.png') no-repeat right center;
  font-size: 1rem;
  color: #fff;
  transition: 0.6s all;
  font-style: normal;
}
div#immp > div#immp-content > a:hover > i {
  padding-right: 58px;
  transition: 0.6s all;
}
/* 五楼联系我们 */
div#contant {
  position: absolute;
  top: 3140px;
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
}
div#contant > div#contant-content {
  position: relative;
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
  background: #fff;
  border-radius: 13px;
  box-shadow: 0px 1px 14px 0px rgba(60, 55, 255, 0.23);
  padding: 1.875rem 1.875rem 5rem 1.875rem;
  box-sizing: border-box;
}
div#contant > div#contant-content div#title > a {
  margin: 0 auto;
  font-size: 26px;
  color: #0066e4;
  height: 40px;
  line-height: 40px;
  padding-bottom: 10px;
  border-bottom: 1px solid #2784ff;
  display: table;
  text-decoration: none;
  outline: none;  
  margin-bottom: 50px;
}
div#contant > div#contant-content > div#contant-card:after {
  clear: both;
  content: '';
  display: block;
  width: 0;
  height: 0;
  visibility: hidden;
}
div#contant > div#contant-content > div#contant-card > div.card-content {
  float: left;
  width: 25%;
}
div#contant > div#contant-content > div#contant-card > div.card-content > a {
  display: block;
  padding: 0 1.25rem;
  text-decoration: none;
  outline: none;
}
div#contant > div#contant-content > div#contant-card > div.card-content > a > div.card-pic {
  margin-bottom: 1.875rem;
  transition: 0.6s all;
}
div#contant > div#contant-content > div#contant-card > div.card-content > a:hover > div.card-pic {
  margin-bottom: 1.25rem;
  transition: 0.6s all;
}
div#contant > div#contant-content > div#contant-card > div.card-content > a > div.card-pic > img {
  display: block;
  margin: 0 auto;
}
div#contant > div#contant-content > div#contant-card > div.card-content > a > p {
  display: block;
  font-size: 1.125rem;
  color: #333333;
  text-align: center;
  line-height: 1.875rem;
  height: 3.75rem;
  /* word-break: break-all; */
}

div#abouts-content::after {
    content: "";
	clear: both;
	content: '';
	display: block;
	width: 0;
	height: 0;
	visibility: hidden;
}
div#page-footer {
  position: relative;
  top: 2900px;
}
</style>