<template>
  <div calss="swiper-container" v-swiper:mySwiper="swiperOptions">
    <div class="swiper-wrapper">
      <div class="swiper-slide" :key="index" v-for="item,index in dataList">
        <router-link to="/profile">
          <img :src="require('../../../../../assets/'+ `${item}` + '.jpg')">
        </router-link>
      </div>
    </div>
    <div class="swiper-button-prev"></div><!--左箭头。如果放置在swiper-container外面，需要自定义样式。-->
    <div class="swiper-button-next"></div><!--右箭头。如果放置在swiper-container外面，需要自定义样式。-->
  </div>
</template>

<script>
export default {
  name: "ImmpPic",
  props: {
    dataList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      swiperOptions: { 
        loop: true,   // 循环播放
        breakpoints: { 
          320: {  //当屏幕宽度大于等于320
            slidesPerView: 2,
            spaceBetween: 50
          },
          768: {  //当屏幕宽度大于等于768 
            slidesPerView: 4,
            spaceBetween: 50
          },
          1280: {  //当屏幕宽度大于等于1280
            slidesPerView: 5,
            spaceBetween: 50
          }
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  },
  mounted() {
    this.moveAction();
  },
  methods: {
    // 自动播放
    moveAction() {
      setInterval(() => {
        this.mySwiper.slideNext(500, false);
      }, this.dataList.length * 600);
    },
  }
}
</script>

<style scoped>
div.swiper-slide > a > img {
  display: block;
  border: 2px solid #fff;
  box-shadow: 0px 1px 16px 0px rgba(0, 12, 59, 0.51);
  box-sizing: border-box;
  width: 204px;
  transition: 0.6s all;
}
div.swiper-slide > a > img:hover {
  transform: scale(1.1);
  transition: 0.6s all;
}
.swiper-container{
    /* --swiper-theme-color: #1d1d1dd5;设置Swiper风格 */
    /* --swiper-navigation-color: #292929ce;单独设置按钮颜色 */
    --swiper-navigation-size: 20px;    /* 设置按钮大小 */
  }
.swiper-button-prev,
.swiper-button-next {
  width: 20px;
  height: 20px;
  padding: 5px;
  border: 2px solid #fff;
  border-radius: 50%;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
  transition: 0.6s all;
}
.swiper-button-prev:hover,
.swiper-button-next:hover {
  transform: scale(1.1);
  transition: 0.6s all;
  background-color: rgba(0, 0, 0, 0.5);
}
</style>